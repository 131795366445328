<template>
  <div v-if="!loading" id="exampleImageCarousel" :class="styleClass" data-bs-ride="carousel">
    <div class="carousel-inner" role="listbox">
      <div v-for="(image, index) in images" :key="index" class="carousel-item" :class="[ index === 0 ? 'active' : '' ]">
        <a
          class="col-sm-3 col-3 configurator__carousel-i"
          data-bs-toggle="modal"
          href="#exampleImageModal">
          <img
            :src="$filters.image(image.path)"
            class="configurator__carousel-img"
            :data-image-id="image.path"
          />
        </a>
      </div>
    </div>
    <a
      class="carousel-control-prev bg-transparent"
      href="#exampleImageCarousel"
      role="button"
      data-bs-slide="prev"
    >
      <i class="fas fa-chevron-left"></i>
    </a>
    <a
      class="carousel-control-next bg-transparent"
      href="#exampleImageCarousel"
      role="button"
      data-bs-slide="next"
    >
      <i class="fas fa-chevron-right"></i>
    </a>
  </div>
</template>
<script>
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
import { onMounted } from '@vue/runtime-core'
import _ from 'lodash'
// import { Modal } from 'bootstrap'
export default {
  name: 'Carousel',
  props: {
    styleClass: {
      type: String
    }
  },
  setup () {
    const store = useStore()
    const images = computed(() => {
      const imgs = store.getters['configurator/exampleImages']
      const result = _.orderBy(imgs, ['sort'], ['asc'])
      return result
    })
    const loading = computed(() => {
      return store.getters['configurator/exampleImagesLoading']
    })
    onMounted(() => {
      // setTimeout(() => {
      initCarousel()
      // }, 500) // href="#exampleImageModal"
      document.querySelector('#exampleImageCarousel').addEventListener('click', (e) => {
        setImageIndex(e)
      })
    })
    function setImageIndex (e) {
      const index = e.target.dataset.imageId
      store.commit('configurator/SET_EXAMPLE_IMAGE_INDEX', index)
    }
    function initCarousel () {
      const items = document.querySelectorAll('.configurator__carousel .carousel-item')
      const minPerSlide = 4
      items.forEach(el => {
        let next = el.nextElementSibling
        for (let i = 1; i < minPerSlide; i++) {
          if (!next) {
            next = items[0]
          }
          const cloneChild = next.cloneNode(true)
          el.appendChild(cloneChild.children[0])
          next = next.nextElementSibling
        }
      })
    }
    function openModal (i) {
      console.log(i)
      // const modal = new Modal()
      // modal.show()
    }
    return {
      images,
      loading,
      openModal
    }
  }
}
</script>
