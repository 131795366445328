<script setup>
import { useStore } from 'vuex'
import { computed } from '@vue/reactivity'
import TooltipGalleryCarousel from '@/pages/configurator/common/tooltipGalleryCarousel.vue'

// use
const store = useStore()

const status = computed(() => store.getters['popup/status'])
const tooltip = computed(() => {
  const tooltip = store.getters['popup/tooltip']
  if (tooltip && tooltip.gallery) {
    const active = tooltip.gallery.find(i => i.active)
    if (active) {
      return {
        text: tooltip.text,
        image: active.path,
        gallery: tooltip.gallery,
        type: active.type
      }
    }
  }
  return store.getters['popup/tooltip']
})
function closeModal () {
  store.dispatch('popup/togglePopup', false)
}
</script>

<template>
  <div class="modal fade " :class="{'show': status }" id="tooltipModal" tabindex="-1" aria-labelledby="tooltipModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal"></button>
        </div>
        <div v-if="tooltip" class="modal-body">
          <p v-if="tooltip.text" :class="{ 'mb-0': !tooltip.image, 'mb-2': tooltip.image }" v-html="tooltip.text"></p>
          <img class="img-fluid" :src="tooltip.image" v-if="tooltip.image && tooltip.type === 'blind'">
          <div v-else-if="tooltip.image" class="text-center mb-3">
            <iframe v-if="tooltip && tooltip.type === 'video'" id="player" type="text/html" :src="`https://www.youtube.com/embed/${tooltip.image}?enablejsapi=1&origin=http://example.com`" width="570" height="300" frameborder="0"/>
            <img v-else class="img-fluid" :src="$filters.image(tooltip.image, '350x350')">
          </div>
          <h6 v-if="tooltip.gallery">Erkunden Sie weitere Detailbilder:</h6>
          <TooltipGalleryCarousel v-if="tooltip.gallery" :gallery="tooltip.gallery"/>
        </div>
      </div>
    </div>
  </div>
</template>
